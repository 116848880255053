import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

const TermsAndConditions = props => (
  <Layout>
    <Helmet>
      <title>Terms And Conditions</title>
      <meta name="description" content="Terms And Conditions" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Terms And Conditions</h1>
          </header>
          <h3>1. Term</h3>
          <p>
            Your agreement will renew on a month-to-month basis at the then
            current rate after the expiration of the Term.  You can terminate
            your agreement during the renewal period by providing us with thirty
            (30) days’ advance written notice of termination of the Agreement. 
            You will be charged fees through the effective date of termination. 
            The facilities and services are immediately available to the Member
            on the date of this Agreement. In addition to your right to
            terminate under Section 13, you may also cancel your agreement
            during the first thirty (30) days of your initial term by giving
            written notice of cancellation to us and you will receive a full
            refund of all payments that you made to us. If you PAID IN FULL for
            your sessions, then upon expiration of your current Term your
            agreement will renew on a month-to-month basis at the then current
            rates after expiration of the Term. Except as otherwise stated
            herein, all fees and charges that are PAID IN FULL for sessions are
            non-refundable.
          </p>
          <h3>2. Payment.</h3>
          <p>
            You agree to pay the Total Due Today upon signing this Agreement.
            You agree to pay the fees established above via  “automatic”
            withdrawal or electronic fund transfer from the account you provide
            us.  You agree to the initial Agreement term shown above and the
            automatic withdrawals (payments) will continue for that period and
            any period thereafter in which you continue your membership unless
            this Agreement is terminated in writing as provided herein. Fees may
            be modified from time to time by us in our sole discretion. You will
            be charged a NSF fee of $25.00 for any payments that are denied or
            not processed due to insufficient funds in your account.
          </p>
          <h3>3. Authorization.</h3>
          <p>
            You hereby authorize us to access information from the
            checking/debit/credit account indicated above, to withdraw funds
            from that account each month for your fees/charges hereunder, and to
            initiate credit and/or debit entries by EFT or ACH transfer from the
            account. This authority is to remain in full force and effect until
            we have received written notification from you of its termination in
            such time and in such manner as to afford us a reasonable
            opportunity to act on it.
          </p>
          <h3>4. Specific Cancellation Provisions.</h3>
          <p>
            Except as otherwise provided below, you may cancel this Agreement
            for any of the following reasons:
          </p>
          <p>
            (a) If you are pregnant or have an illness (that is not a
            disability) which precludes you from using the facility for three
            (3) consecutive months and the condition is verified by a
            physician.  Upon cancellation under this paragraph, you will be
            assessed a charge equal to 50% of the fees per month for each month
            remaining in the Term of this Agreement and any fees paid to us in
            excess of the aforementioned total cancellation charge will be
            refunded to you.
          </p>
          <p>
            (b) In the event of your death or if you are disabled (and the
            disability is verified by a physician) which precludes you from
            using the facility for three (3) consecutive months. Upon
            cancellation under this paragraph, you will be liable for only that
            portion of the total consideration paid to us proportional to the
            elapsed time portion of the Contract at the time of death of
            disability.
          </p>
          <p>
            (c) If you move more than twenty-five (25) miles from the location
            of the Facility. Upon cancellation under this paragraph, you will be
            assessed a charge equal to 50% of the fees per month for each month
            remaining in the Term of this Agreement and any fees paid to us in
            excess of the aforementioned total cancellation charges will be
            refunded to you.
          </p>
          <p>
            (d)  If the Facility closes or is not operational for more than
            thirty (30) consecutive days and we fail to provide you a comparable
            workout option within ten (10) miles of the closed Facility in which
            you agree to complete this Agreement and receive services at the
            other location. Upon cancellation under this paragraph, you shall be
            entitled to a refund of that portion of the fees that you paid in
            advance for the remaining months in the Term of this Agreement.
          </p>
          <p>
            (e)  If your wish to cancel this Agreement as provided above, then
            such cancellation notice shall be in writing and delivered
            personally or by certified mail to us at the address stated above in
            this Agreement.
          </p>
          <p>
            (f) If you become unemployed during the course of your contract and
            can provide proof of unemployment your membership can be terminated
            without penalty or 30 day notice. You can also choose to suspend
            your agreement until such time you regain employment. At that time
            you can resume sessions at your previous rate.
          </p>
          <h3>5. Default/Remedies.</h3>
          <p>
            Any monthly payment which is received more than ten (10) days after
            the due date shall be subject to a late charge of $20.00 for the
            added administrative costs associated with same.  Any payment that
            remains unpaid for thirty (30) days may result (in our sole
            discretion) in the termination of your membership, the suspension of
            some or all membership privileges, and/or other actions permitted by
            law or in equity.  Closing of account, insufficient funds or any
            other action which prevents the automatic charge for your monthly
            dues, if applicable, will be a material breach of this Agreement.
            You agree to pay reasonable attorney fees, legal expenses, and other
            lawful collection costs and expenses of collection incurred by us as
            a result of your material breach of this Agreement.
          </p>
          <h3>6. Facility and Services.</h3>
          <p>
            You shall have access to the fitness area, equipment, and machines
            in the Facility. Your Fee does not cover lessons, classes,
            individual or group training sessions, special programs, or rentals,
            some or all of which may be made available from time to time for
            additional charges.  Not all programs, benefits, facilities,
            equipment, etc. will be available at all times.  Fees are not
            assignable or transferable and any attempted transfer or assignment
            shall be null and void.
          </p>
          <h3>7. Assumption of Risk.</h3>
          <p>
            You assume all risks while using and participating in activities at
            the Facility.  You assume the risk of any injury, damage, or loss
            that you may sustain as a result of participating in the fitness
            activities and programs at the Facility. You hereby waive,
            discharge, indemnify, and release Karmen Towne Training, its owners,
            managers, directors, officers, agents, trainers, consultants,
            contractors, and employees from and against any and all liabilities,
            injuries, death, loss, claims, suits, damages, or demands, attorney
            fees, costs, and expenses of any nature, which you now have or may
            have in the future, arising from or pertaining to this Agreement,
            your use of the facilities, and the fitness and physical activities
            that you perform at the Facility, unless the same is caused by the
            negligence or intentional acts of Karmen Towne Training.
          </p>
          <h3>8. Rules and Regulations.</h3>
          <p>
            We operate under rules and regulations established for the safety
            and protection of our clients. You agree to abide by all posted
            rules and regulations, as well as by rules and regulations
            subsequently approved and posted by us.  Our rules and policies are
            subject to change, without prior notice, at our sole discretion. You
            accept such reasonable changes as a condition of training with us.
          </p>
          <h3>9. Governing Law.</h3>
          <p>
            This Agreement shall be governed by and construed under the law of
            the State of Wisconsin without regard to the principles of conflicts
            of law thereunder.  The rights and obligations under this Agreement
            shall inure to and be binding on the parties hereto, their
            respective heirs, successors and assigns.
          </p>
          <h3>10. Assignment/Severability.</h3>
          <p>
            You shall not assign this Agreement without Lessor’s prior written
            approval.  We shall be permitted to assign this Agreement to another
            third party upon advanced written notice to you.  If any term or
            condition of this Agreement, or the application of this Agreement to
            any person or circumstance, shall be deemed invalid or
            unenforceable, the remainder of this Agreement shall not be affected
            thereby, and each term and condition shall be valid and enforceable
            to the fullest extent permitted by law.
          </p>
          <h3>11. Binding.</h3>
          <p>
            The rights and obligations under this Agreement shall inure to and
            be binding on the parties hereto, their respective heirs, successors
            and assigns.
          </p>
          <h3>12. Entire Agreement.</h3>
          <p>
            The terms and conditions set forth herein, along with the attached
            Waiver and Release, constitute the complete understanding and
            agreements with regard to the subject matter of this Agreement, and
            supersedes any prior or subsequent agreements of the parties.
          </p>
          <h3>13. CANCELLATION AND REFUNDS (RIGHT TO CANCEL).</h3>
          <p>
            You are permitted to cancel this Contract until midnight of the 3rd
            operating day after the date on which you signed the contract. If
            the facilities or services that are described in this Contract are
            not available at the time you sign the Contract, you have until
            midnight of the 3rd operating day after the day on which you
            received notice of their availability, to cancel the Contract. If
            within this time period you decide you want to cancel this Contract,
            you may do so by notifying Karmen Towne Training by any writing
            mailed or delivered to Karmen Towne Training at the address shown on
            the Contract, within the previously described time period. If you do
            so cancel, any payments made by you, less a user fee of no more than
            $3 per day of actual use, will be refunded within 21 days after
            notice of cancellation is delivered, and any evidence of any
            indebtedness executed by you will be canceled by Karmen Towne
            Training and arrangements will be made to relieve you of any further
            obligation to pay the same.
          </p>
          <p>
            THE TERMS AND CONDITIONS SET FORTH ABOVE, ALONG WITH THE WAIVER AND
            RELEASE ATTACHED HERETO, MAKE UP THE ENTIRE BINDING CONTRACT BETWEEN
            THE PARTIES (“AGREEMENT”). CLIENT AGREES TO BE BOUND BY THE TERMS
            AND CONDITIONS SET FORTH IN THIS AGREEMENT. BY SIGNING BELOW, THE
            CLIENT AGREES THAT HE/SHE HAS READ THIS AGREEMENT, UNDERSTANDS THE
            TERMS OF THIS AGREEMENT, AND ENTERS INTO THIS AGREEMENT FREELY AND
            VOLUNTARILY. BY SIGNING BELOW, YOU ACKNOWLEDGE THAT YOU HAVE
            RECEIVED A COPY OF THIS SIGNED AGREEMENT.
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default TermsAndConditions;
